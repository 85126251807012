<template>
  <div>
    <h2 class="f-w-600">
      <strong class="f-w-700">Confirma tu email</strong>
    </h2>
    <h5>
      <strong class="f-w-700 text-success my-5" v-text="$route.query.email"> </strong>
    </h5>
    <hr>
    <h5 class="my-4">
      Por favor revisa tu correo electrónico para confirmar tu cuenta.
    </h5>
    <p class="my-4">
      Si <strong class="f-w-600" v-text="$route.query.email"> </strong> no es tu dirección de correo electrónico
      <router-link to="/accounts/signup" v-text="'regresa'"></router-link> e ingresa la correcta.
    </p>
    <hr />
    <p class="my-4">
      Si no has recibido nuestro correo electrónico en 2 minutos, verifica tu carpeta de correo no deseado.
    </p>
    <p>Si aún no lo has recibido, <br> comunícate con soporte (<a href="mailto:support@ecart.com"
        target="_blank">support@ecart.com</a>).
      <br>
      <br>
      <b-button variant="link" :to="{ path: '/accounts/login', query: { ...$route.query }}">
        Iniciar sesión
      </b-button>
    </p>
  </div>
</template>

<script>
  export default {

  }
</script>

<style>

</style>